<script setup lang="ts">
import type { ApiResponse } from '@/types/misc'
import type { Appointment } from '~/types/calendar'

const props = defineProps<{
  carerId?: string
}>()

const emit = defineEmits(['refreshLayout'])

const nuxtApp = useNuxtApp()
const userStore = useUserStore()
const clinicStore = useClinicStore()

const appointments = ref<Partial<ApiResponse<Appointment>>>()
const fetchAppointments = ref(true)
const isLoading = ref(true)
const scheduleListRef = ref<HTMLElement | null>(null)
const wasIntersected = ref(false)

const getCarerId = computed(() => props.carerId ?? userStore.auth?.user?.id)

async function getAppointments(showSkeleton: boolean = false) {
  if (!wasIntersected.value) return

  // Only show skeleton if there's no data. To avoid quick reflashes when refreshing data.
  if (!appointments.value || showSkeleton) {
    isLoading.value = true
    await nextTick()
    emit('refreshLayout')
  }

  const appointmentsList = await nuxtApp.$api
    .getAppointments(
      fetchAppointments.value
        ? `date_from=${nuxtApp.$dayjs().toISOString()}&carer=${
            getCarerId.value
          }`
        : `date_from=${nuxtApp.$dayjs().toISOString()}`,
      5,
      true,
    )

    .catch((error) => {
      showToast('error', 'Erro', error)
      return { items: [] }
    })

  if (appointmentsList.items.length > 0) {
    // Remove deleted appointments
    appointmentsList.items = appointmentsList.items.filter(
      (appointment: Appointment) => !appointment.cancelled_at,
    )

    // Remove appointments that are all day
    appointmentsList.items = appointmentsList.items.filter(
      (appointment: Appointment) => !appointment.schedule_all_day,
    )

    // Sorting the appointments by date
    appointmentsList.items.sort(
      (objA, objB) =>
        new Date(objA.schedule_start).getTime() -
        new Date(objB.schedule_start).getTime(),
    )
  }

  appointments.value = appointmentsList
  isLoading.value = false
  await nextTick()
  emit('refreshLayout')
}

const { stop } = useIntersectionObserver(
  scheduleListRef,
  ([{ isIntersecting }]) => {
    if (!isIntersecting) return

    wasIntersected.value = isIntersecting
    getAppointments()
    stop()
  },
)

watch(fetchAppointments, () => {
  getAppointments(true)
})

watch(
  () => clinicStore.websocket.last_appointment_change,
  async () => {
    await getAppointments(true)
  },
)
</script>

<template>
  <BaseCard
    ref="scheduleListRef"
    title="Próximas Marcações"
    :show-header-icon="!carerId"
  >
    <template #headerIcon>
      <UButton
        color="primary"
        label="Pessoais"
        :variant="fetchAppointments ? 'solid' : 'soft'"
        size="xs"
        class="mr-2"
        @click="fetchAppointments = true"
      />
      <UButton
        color="primary"
        :variant="fetchAppointments ? 'soft' : 'solid'"
        label="Clínica"
        size="xs"
        @click="fetchAppointments = false"
      />
    </template>

    <template #content>
      <div v-if="isLoading" class="space-y-4">
        <USkeleton v-for="i in 2" :key="i" class="h-24" />
      </div>

      <NuxtLink
        v-else-if="!appointments?.count"
        to="/calendar/"
        class="transition-colors duration-300 hover:text-sky-500"
      >
        <BaseEmptyState
          icon="i-heroicons-calendar-days"
          title="Não existem próximas marcações."
          description="Crie uma nova marcação para um paciente."
        />
      </NuxtLink>

      <div
        v-for="(appointment, index) in appointments.items"
        v-else
        :key="appointment.id"
      >
        <NuxtLink
          :to="`/patients/${appointment.patient}/appointments/?id=${appointment.id}`"
        >
          <UiCardAppointmentItem
            :appointment="appointment"
            :show-patient-name="true"
            :show-date="false"
            :show-relative-time="true"
          />
        </NuxtLink>

        <hr v-if="index !== (appointments.items?.length ?? 0) - 1" />
      </div>
    </template>
  </BaseCard>
</template>
